<template>
  <div class="login-page">
    <div class="logo-wrap">
      <img src="/logo.png" class="logo" />
      <p>一秒·私人云服务</p>
    </div>
    <div class="login-panel">
      <h1>登录/注册</h1>
      <p class="login-tip">若未注册过，登录成功即帮您成功注册账号</p>
      <p class="input-header">邮箱地址</p>
      <el-input
        placeholder="请填写真实邮箱地址"
        v-model.trim="email"
        @keyup.enter.native="register"
      />
      <p class="input-header">邮箱验证码</p>
      <el-input
        class="dark"
        placeholder="邮箱验证码"
        v-model.trim="emailcode"
        @keyup.enter.native="register"
      >
        <template slot="append">
          <el-button
            type="text"
            :disabled="leftSecond > 0"
            @click="handleSendCode"
            :style="{
              color: leftSecond > 0 ? '#909399' : '#4A3AFF',
            }"
            >{{
              leftSecond > 0 ? `${leftSecond}秒后重新获取` : "获取验证码"
            }}</el-button
          >
        </template>
      </el-input>
      <van-button
        type="primary"
        size="normal"
        block
        @click="login"
        :loading="loading"
        :disabled="!emailcode || !email"
        >登录</van-button
      >
      <p class="tip">
        登录成功即表示您已同意本站用户协议
        <a href="/#/about/agree" target="_blank">《用户协议》</a>
      </p>
    </div>
  </div>
</template>

<script>
import { loginByEmail, sendEmailCode } from "@/api/loginByEmail.js";
import { isValidEmail } from "@/utils/validate.js";

export default {
  data() {
    return {
      show: true,
      email: "",
      emailcode: "",
      loading: false,
      timer: null,
      leftSecond: 0,
    };
  },
  destroy() {
    this.leftSecond = 0;
    clearInterval(this.timer);
  },
  methods: {
    async login() {
      if (!this.email || !this.emailcode) {
        return this.$toast.fail("请填写邮箱、密码和验证码");
      }
      const res = await loginByEmail({
        email: this.email,
        emailcode: this.emailcode,
      });
      if (res.code == 0) {
        this.$toast.success("登录成功");
        localStorage.setItem("token", res.data.token);
        location.reload();
      } else {
        return this.$toast.fail(res.message);
      }
    },
    handleCalLeftSecond() {
      this.leftSecond = 60;
      this.timer = setInterval(() => {
        if (this.leftSecond === 1) {
          clearInterval(this.timer);
        }
        this.leftSecond -= 1;
      }, 1000);
    },
    async handleSendCode() {
      if (!isValidEmail(this.email)) {
        return this.$toast.fail("请输入正确的邮箱地址");
      }
      sendEmailCode({ email: this.email }).then((res) => {
        if (res.code === 0) {
          this.handleCalLeftSecond();
          if (res.message) this.$toast.success(res.message);
        } else {
          return this.$toast.fail(res.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.login-page {
  padding-top: 120px;
  .logo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bolder;
    margin-bottom: 40px;
    img {
      height: 40px;
      margin-right: 10px;
    }
  }
}

.login-panel {
  max-width: 100%;
  padding: 40px;
  width: 532px;
  border-radius: 8px;
  background-color: #fff;
  margin: 0 auto;
  h1 {
    font-size: 42px;
  }
  .login-tip {
    font-size: 14px;
    color: #5f646d;
    margin-bottom: 30px;
  }
  .e-input {
    font-size: 16px;
  }
  .input-header {
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .van-button {
    margin-top: 50px;
    height: 52px;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .tip {
    font-size: 12px;
    color: #999;
  }
}

@media (max-width: 700px) {
  .login-page {
    padding-top: 100px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .login-panel {
    padding: 20px;
    h1 {
      font-size: 22px;
    }
  }
}
</style>
